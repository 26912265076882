

.-gantt-container {
  display: flex;
  font-family: 'Poppins', sans-serif !important;
  flex-direction: column;
  align-items: flex-end; /* Anchor to the left */
}

.-gantt-switcher-container {
  display: flex;
  justify-content: center;
  margin: 32px 0;  
  font-family: 'Poppins', sans-serif !important;
}

.-gantt-switcher-button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  background-color:white;
  border-radius:6px;
  border:0;
    padding:6px 16px;
    font-family: 'Poppins', sans-serif !important;
    text-transform: capitalize;
}

.-gantt-switcher-button.active {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
    background-color:var(--accent-color);
  }
  

.-gantt-table-wrapper {
  overflow-x: auto;
  width: 100%;
  
  border-radius: 6px; /* Rounded corners */
}

.-gantt-table {
  border-collapse: collapse;
  width: 100%;
 
}
.-gantt-header, .-gantt-legend {
  background-color: #f0f0f0;
}

.-gantt-header-cell, .-gantt-legend-cell, .-gantt-cell {
  border: 1px solid #f0f0f0;
  text-align: center;
  min-width: 10px;
  padding: 5px;
  background-color: white;
  border-radius: 6px;
}
.-gantt-header-cell{
    text-transform: uppercase;
    background-color: #f4f4f4;
    border-right: solid 2px #fff;
}
.-gant-col1 {
    min-width: 250px;
    max-width: 250px;
    width: 250px;
    text-align: left;
    font-family: 'Poppins', sans-serif !important;
    overflow: hidden;
    white-space: nowrap; /* Corrected: removed quotes */
    text-overflow: ellipsis; /* Corrected: removed quotes */
    text-align:left;
    padding:6px 3px 6px 10px;
}
.-gant-col1-legend{
    font-weight: bold;
}
.-gantt-fixed-cell {
  position: sticky;
  left: 0;
  background-color: #f9f9f9;
  z-index: 1;
  background-color: var(--accent-highlight-color-full) !important; 
}

.-gantt-row {
  background-color: white;
}

.-gantt-event {
  height: 20px;
  border-radius: 4px;
}
