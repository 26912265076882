.week-view-calendar {
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
    width: 100%;
    height: 100%;
  }
  
  .week-controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding:16px;
  }
  
  .week-controls button,
  .week-controls select,
  .week-controls input {
    padding: 5px 10px;
    font-size: 13px;
    margin-right: 5px;
    cursor: pointer;
    border:solid 1px var(--accent-color);
    font-family: "poppins";
    border-radius: 6px;
  }
  
  .week-header {
    display: flex;
  }
  
  .day-header {
    flex: 1;
    padding: 10px;
    /* background-color: #f4f4f4; */
    text-align: center;
    border-left: 1px solid #ddd;
  }
  
  .week-events {
    display: flex;
    flex: 1;
    height: 100%;
  }
  
  .day-column {
    flex: 1;
    padding: 10px;
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    min-height: 100px;
    border-bottom:solid 0;
  }
  
  .event {
    background-color: #add8e6;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 4px;
    font-size: 14px;
  }
  