:root {
  --primary-color: #EFEFEF;
  --secondary-color: #433F3A;
  --accent-color: #C9B39B;
  /* --accent-color: #C9B39B; */
  --black-color: #000;
  --white-color: #FFF;
  --green-color-dark: #248320;
  --green-color:#0dcc05;
  --orange-color: #eb8800;
  --red-color: #C12525;
  --violet-color: #C562D3;
  --label-1: #45A2CC;
  --label-2: #B4E252;
  --label-3: #C562D3;
  --label-4: #F9CB4E;
  --label-5: #A5A5A5;
  --tab-width:300px;
  --drawer-height:200px;
  --accent-light-color:#c9b39b0d;
  --accent-light-alt-color:#c9b39b3b;
  --accent-highlight-color:#ffe0be1f;
  --accent-highlight-color-full:#fdfaf6;
  --accent-thin-color:#ede0d3;
}
.topper-small {
  margin-top: 6px;
}
.legend {
  font-family: 'Poppins',sans-serif;
  font-size: 11px;
  font-weight: 400;
}

.flex{
  display:flex;
}

.flex-column {
  justify-content: space-between;
  align-items: center;
}

.flex-row {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}


.flex-column-center {
  justify-content: center;
  align-items: center;
}

.flex-column-start {
  justify-content: flex-start;
  align-items: center;
}

.flex-column-end {
  justify-content: flex-end;
  align-items: center;
}

.icon-table{
  font-size: 1.1rem;
  color: var(--accent-color); 
  margin-right:16px;
}
.icon-table-small{
  font-size: 1rem;
  color: var(--accent-color); 
  margin-right:16px;
}
.icon-table.agenda{
  font-size: 1.1rem;
  color: var(--accent-color); 
  margin-right:6px;
}
/* 
input:focus {
  outline: none !important;
}
input[type="text"],
input[type="text"]:focus {
  background: transparent !important;
} 
input[type="text"] {
  background: none;
  background-color: transparent;
} 
*/

body {
  background-color: var(--secondary-color);
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--accent-color);
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #fff;
}
a.link {
  color:var(--accent-color) !important;
}

.text-input{
  font-family: "Poppins", sans-serif;
  border: solid 1px var(--accent-color) !important;
  background-color: white !important;
  background: white !important;
  margin: 10px;
  width: 270px;
  height: 40px;
  font-size: 16px;
  color: var(--accent-color) !important;
  font-weight: bold;
}
input::placeholder,
.text-input::placeholder{
  color:var(--accent-color);
}

h1 {
  font-family: "Poppins", sans-serif;
  font-size: 36px;
  letter-spacing: -1px;
  line-height: 24px;
  font-weight: 400;
  color: var(--accent-color);
}

h2 {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  letter-spacing: -1px;
  line-height: 24px;
  font-weight: 400;
  color: var(--accent-color);
}

h3 {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  letter-spacing: -0.5px;
  line-height: 20px;
  font-weight: 400;
  color: var(--accent-color);
}

.h3b {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 12px;
  font-weight: bold;
  color: var(--secondary-color);
}

.h4 {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 12px;
  font-weight: bold;
  color: var(--accent-color);
}

.h4s {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 12px;  
  color: var(--accent-color);
}

.h5 {
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  letter-spacing: 0px;
  line-height: 11px;
}
.h5b {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 12px;
  font-weight: bold;
  color: var(--secondary-color);
}

.h5bl {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 11px;
  font-weight: 418;
}
h4 {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 18px;
  font-weight: 400;
  color: var(--accent-color);
}

h5 {
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  letter-spacing: 0px;
  line-height: 12px;
  font-weight: 400;
  color: var(--secondary-color);
}

p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 18px;
  font-weight: 300;
  color: var(--secondary-color);
}

.App {
  text-align: center;
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;    
    font-family: 'Poppins', sans-serif !important;
}
.inline {
  display: inline;
}
.mobile-menu{
  display: none;
}
.ant-layout,
.ant-menu {
  font-family: 'Poppins', sans-serif !important;
}
.ant-layout-content{
  margin:0 !important;
}

.ant-spin-dot-holder{
  color:var(--accent-color) !important;
}
.spinner {
  margin-left:16px;
  /* position: absolute; */
  /* display: flex;
  height: 50vh;
  width: 50vw;
  margin: auto;
  justify-content: center;
  align-items: center; */
}
.spinner-dashboard {
  display: flex;
  justify-content: center;
  margin-top:64px;
}
aside ,
.ant-layout-sider,
.ant-menu-dark{
  background-color: var(--secondary-color) !important;
}
.ant-layout-sider-trigger{
  background-color: var(--secondary-color) !important;
  top: 0;
  bottom: initial;
  color:var(--accent-color) !important;
}

.pagination-list{
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.ant-pagination-item-active{
  border-color: var(--accent-color) !important;
}
.ant-pagination .ant-pagination-item-active a{
  color:var(--accent-color) !important;
}
.ant-menu-item-selected {
  background-color: var(--accent-color) !important; 
  color: white;
}


.accented .ant-select-selection-item{
  /* color:var(--accent-color); */
  font-weight: 900;
}

.bold .ant-select-selection-item{
  /* color:var(--accent-color); */
  font-weight: 700;
}

.tab-content .ant-select-multiple .ant-select-selection-item,
.user-select .ant-select-selection-item,
.filter-container .ant-select-selection-item {
  background-color: var(--accent-color) !important; 
  color: white !important;
  line-height: 2rem;
  height: 2rem;
}
.tab-content .ant-select-selection-item svg,
.user-select .ant-select-selection-item svg,
.filter-container .ant-select-selection-item svg{
  color:white;
}

.filter-special .ant-select-selection-item{
  background-color: #ffffff00 !important; 
  color: var(--accent-color) !important;
}
.tag-large,
.tag-fix,
.tag {
  padding: 4px 8px 4px 8px;
  text-align: center;
  font-weight: bold;
  border-radius: 5px;
  color: white;
  font-family: 'Poppins', sans-serif !important;
  font-size:12px;  
}
.tag-fix{
  width:40px;
  padding: 2px 4px;
}
.tag-large{
  padding:3px 20%;
}
.sider{
  padding-top:60px !important; 
}

.icon-content {
  font-size: 16px;
  line-height: 16px;
  color: var(--accent-color);
  margin: 0;
  border: solid 1px var(--accent-color);
  border-radius: 11px;
  height: 22px;
  width: 22px;
  padding:2px;
  background-color: #efefef;
}

.icon-header {
  font-size:21px;
  color:var(--accent-color);
  margin:0 16px 0 16px;
}
.icon-save {
  font-size:21px;
  color:#e3e3e3;
  margin:0 16px 0 16px;
}
.icon-save:hover{
  color:var(--accent-color);
}
.img-bw {
  -webkit-filter: grayscale(100%); 
  filter: grayscale(100%);
}
.header{
  width: 100%;
  background-color: #fff;
  position: fixed;
  align-items: center;
  top: 0;
  height: 80px;
  z-index: 99;
  display: flex;
  justify-content: flex-start;
  padding-left: 16px;
  border-bottom: solid 1px #e3e3e3;
}

.header-block{
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}

.header-search{
  margin-right: 64px !important;
  /* width: 400px; */
}
.header-search .ant-input-outlined:focus,
.header-search .ant-input-outlined{
  border:0 !important;
  box-shadow: none !important;
}

.header-search input{
  border:0 !important;
  border-bottom: solid 1px var(--accent-color) !important;
  outline:none !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 16px !important;
  width: 270px !important;
  color: var(--accent-color) !important;
  padding: 0;
  margin: 0;
}
/* .header-search input:focus{
   border:0 !important; 
} */
input::placeholder,
textarea::placeholder,
select::placeholder,
.ant-select-selection-placeholder,
.header-search input::placeholder{
  color: var(--accent-color) !important;
}
.header-search .ant-input-search-button {
  border:0 !important;
  color: var(--accent-color) !important;
  font-size: 21px;
}
.header-search .ant-input-search-button svg{
  font-size: 21px;
}
.sidebar-button{
  padding-left:0;
  margin-left:0; 
  text-align:left;
  color:#ababab;
  font-size:16px;
}
.sidebar-button:hover{
  color:#ffffff !important;
}
.content{
  position: relative;
  width: 90%;
  margin: 0 auto 0 auto;
  padding: 6px;
  top:40px;
}

.content-block {
  margin: '16px';
  overflow : 'auto';
  height: '100vh';
  border:'solid'
}

.content .ant-card,
.ant-pagination,
.ant-pagination .ant-pagination-item  {
  font-family: 'Poppins', sans-serif !important;
  font-size:14px;
}

.ant-card-body{
  padding: 0 !important;
}

.ant-card-cover{
  display: flex;
  justify-content: flex-end
}

.uppercase{
  text-transform: uppercase;
}

.ant-select-item-option-content,
.capitalize::first-letter{
  text-transform: capitalize;
}
.capitalize-full{
  text-transform: capitalize;
}
.uncapitalize{
  text-transform: lowercase;
}
.uppercase{
  text-transform: uppercase;
}

.card-content {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left:10%;
  flex:1;
  text-align:left;
  font-weight: 618;
  color:black;
  display: flex;
  font-size: 12px;
  flex-direction: column;
  justify-content: space-between;  
  height: 168px; 
}

.file-list span{
/* .card-content span { */
  font-size: 0.8em;
}
.file-list span.big{
  font-size: 1em;
}
.file-list span.bigger{
  font-size: 1.1em;
}
.bold {
  font-family: 'Poppins', sans-serif;
  font-weight: 618;
  font-style: normal;
}
.thin {
  font-family: "Poppins", sans-serif;
  font-weight: 282;
  font-style: normal;
}
.semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.file-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom:16px;
}

.h2 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 618;
  font-size: 13px !important;
  color:var(--accent-color)
}

.h2s {
  font-family: "Poppins", sans-serif !important;
  font-weight: 618;
  font-size: 12px;
  color:var(--accent-color)
}


.h2g {
  font-family: "Poppins", sans-serif !important;
  font-weight: 618;
  font-size: 13px !important;
  color:var(--green-color)
}


.h2r {
  font-family: "Poppins", sans-serif !important;
  font-weight: 618;
  font-size: 13px !important;
  color:var(--red-color)
}


.login-container {
  display: flex;
  width: 400px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spacer{
  margin:0 16px 0 16px;
}

.spacerH{
  margin:16px 0 16px 0;
}

.button-block{
  width: 270px;
  border-radius: 10px;
  height: 60px;
  font-family: "Poppins", sans-serif;
  padding: 0;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--accent-color);
  font-weight: 618;
  padding-left: 2rem;
  font-size: 1.1rem;
  border: solid 1px var(--accent-color);
  background-color: white;
  margin-right: 16px;
}
.button-block:hover{
  color: var(--secondary-color);
  border:solid 1px var(--secondary-color);
}

.button{
  width: 270px;
  border-radius: 20px;
  height: 40px;
  font-family: "Poppins", sans-serif;
  padding:0;
  box-shadow:none;
  cursor:pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-small{
  width: 100px;
  border-radius: 15px;
  height: 30px;  
  font-family: "Poppins", sans-serif;
  padding:0;
  color:black;
}
.button-icon{
  width: 60px;
  border-radius: 15px;
  height: 30px;
  padding:0;
}
.button:hover,
.button-small:hover,
.button-icon:hover{
  background-color:var(--accent-color) !important;
}
.button-small.cancel:hover{
  background-color: white;
  border:solid 1px black;
  color:white !important;
}
.button-small.apply:hover{
  background-color:black !important;
  color:white !important;
}
.button-with-accent {
  font-size: 16px;
  background-color: var(--accent-color)  !important;
  color:black;
  /* border: solid 1px var(--accent-color); */
}
.button-with-accent:hover {
  font-size: 16px;
  color:white;
  background-color: var(--accent-color);
}
.button-secondary {
  font-size: 16px;
  background-color: #c3c2c2b0 !important;
  color:black;
  /* border: solid 1px var(--accent-color); */
}
.button-secondary:hover {
  color:white;
  background-color: #b1b1b1b0 !important;
}
.button-tiny,
.button-tiny:hover{
  width: initial;
  padding:0 16px;
  font-size: 13px;
  color: black;
}
.button-tiny.cancel:hover{
  color:white !important;
}
.secondary-button {
  background-color: #ececec;
  border: solid 1px var(--accent-color);
  border-radius: 20px;
  font-family: "Poppins", sans-serif;
  padding: 4px 10px;
  box-shadow: none;
  cursor: pointer;
}
.secondary-button:hover{
  color:white;
  background-color:var(--accent-color) !important;
}
.secondary-button:hover span{
  color:white !important;
}
.save{
  background-color: var(--secondary-color);
  font-size: 14px !important;
}
.apply{
  background-color:var(--accent-color) !important;
  font-size: 14px !important;
}

.cancel{
  background-color:white !important;
  border: solid 1px var(--accent-color) !important;
  color:var(--accent-color) !important;
  font-size: 14px !important;
}

.grey-dark {
  color:var(--secondary-color) !important;  
}
.grey-dark-radius{
  border-color:var(--secondary-color) !important;  
}
.accent-color-radius{
  border-color:var(--accent-color) !important;  
}
.accent-color{
  background-color:var(--accent-color) !important;
}
.secondary-color{
  background-color:var(--secondary-color) !important;
}
.red-color{
  background-color:var(--red-color) !important;
}
.green-color{
  background-color:var(--green-color) !important;
}
.checkbox{
  margin-left: 16px;
}

.filter-row{
  margin-top:1rem;
}

.filter-row.column{
  display: flex;
  justify-content: space-between;
}
.filter-select{
  min-width: 200px !important;
}
.small-input {
  width:50px;
  padding: 2px 4px 2px 4px;
}

.gutter{
  margin:0 6px 0 6px;
}
.right {
  display: flex;
  justify-content: flex-end;
}
.center{
  text-align: center;
}
/* styles.css */
.custom-checkbox .ant-checkbox-inner  {
  /* background-color:var(--accent-color) !important;
  border:solid 1px var(--accent-color) !important; */
}

.custom-checkbox.radius-it{
  border-radius: 6px;
}

.custom-checkbox .ant-checkbox-checked  .ant-checkbox-inner{
  background-color:var(--accent-color) !important;
  border:solid 1px white !important;
}
.custom-checkbox.has-secondary-color .ant-checkbox-checked  .ant-checkbox-inner{
  background-color:var(--secondary-color) !important;
  border:solid 1px white !important;
}
.custom-checkbox.has-orange-color .ant-checkbox-checked  .ant-checkbox-inner{
  background-color:var(--orange-color) !important;
  border:solid 1px white !important;
}
.custom-checkbox.has-accent-color .ant-checkbox-checked  .ant-checkbox-inner{
  background-color:var(--accent-color) !important;
  border:solid 1px white !important;
}
.custom-checkbox.has-green-color .ant-checkbox-checked  .ant-checkbox-inner{
  background-color:var(--green-color) !important;
  border:solid 1px white !important;
}
.custom-checkbox.has-red-color .ant-checkbox-checked  .ant-checkbox-inner{
  background-color:var(--red-color) !important;
  border:solid 1px white !important;
}
.custom-checkbox .ant-checkbox-inner::after {
  border-color: white; /* Optional: Change the color of the checkbox checkmark */
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after{
  background-color:var(--accent-color) !important;
  border-color: white; /* Optional: Change the color of the checkbox checkmark */
}

.filters{
  background-color:var(--accent-color) ;
  color:white !important;
}
.filter-select-sort, 
.filter-select-sort span{
  color:var(--accent-color) !important;
  border: 0 !important;
}
.filter-select-sort .ant-select-outlined,
.filter-select-sort .ant-select-selector{
  border: 0 !important;
}

.icon-link{
  width: 50px;
  height: 50px;
  color:var(--accent-color) !important;
  font-size: 1.6rem;
}

.icon-link:hover{
  color:var(--secondary-color) !important;
}

.custom-table .ant-table ,
.custom-table {
  font-family: 'Poppins', sans-serif !important;
  /* font-size:13px; */
}

.custom-table td:first-child:not(:last-child){
  background-color: var(--accent-highlight-color) !important;  
}
.custom-table.norowselection td:first-child:not(:last-child){
  background-color: initial !important;  
}
.custom-table .custom-name{
  font-weight: 618;
  color:var(--accent-color);
}

.text-black{
  color:var(--secondary-color) !important;
}

.custom-table.no-padding .ant-table-row >.ant-table-cell{
   padding:0 !important;
}

.custom-table .ant-table-row.ant-table-row-selected >.ant-table-cell{
  background-color: var(--accent-highlight-color) !important;
}

.ant-notification-notice-icon-success.anticon{
  color:var(--accent-color) !important;
}
.ant-select-item-option-selected{
  background-color: var(--accent-color) !important;
  color:#fff !important;
}
.ant-select-selector,
.ant-select-focused .ant-select-selector,
.ant-select-focused .ant-select-selector:hover{
  border:solid 1px var(--accent-color) !important;
  box-shadow:none !important;
  border-color: var(--accent-color) !important;
}

.dashboard-layout-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 2rem;
  justify-content:space-between;
}

.dashboard-layout-column{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}
.dashboard-h3{
  margin-top: 0;
  font-weight: 618;
}
.dashboard-card{
  width: 333px;
  height: 400px;
  padding:20px;
}
.body{
  font-size: 0.8rem;
  color:#000
}
.dashboard-card.large{
  width: 700px !important;
}
.dashboard-metric{
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  gap: 10px;
  
  justify-content: space-around;
  align-items: start;
    justify-items: center;
}
.triangle{
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 16px solid black;
}
.rotate-90{
  transform: rotate(90deg);
}
.circle-small{
  width: 16px;
  height: 16px !important;
  background-color: var(--red-color);
  border-radius: 8px;
  max-height: 16px;
  min-width: 16px;
}
.dashboard-metric-block {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  cursor:pointer;
}
.dashboard-metric-block div {
  text-align: center;
}
.dashboard-circle {
  display: flex;
  width: 132px;
  height: 132px;
  border-style: solid;
  border-radius: 68px;
  border-width: 6px;
  border-color: var(--red-color);
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-weight: 618;
  font-size: 1.8rem;
  background-color: white;
  margin-bottom: 1rem;
}

.accent-color-border {
  border-color:var(--accent-color)
}

.orange-color-border {
  border-color:var(--orange-color)
}

.red-color-border {
  border-color:var(--red-color)
}
.ant-popover-inner{
  font-family: "Poppins", sans-serif !important;
  font-size:0.68rem !important;
  border-radius:6px;
}

.pop-item{
  cursor:pointer;
  margin:10px 0;
}
.pop-item:hover{
  opacity: 0.68;
}

.pop-item div {
  font-size: 14px;
}
.pop-item.small {
  font-size: 10px;
}

.pop-item.stars{
  
}

.divider-list-user {
  padding:0;
  width:80%;
  height: 1px;
  margin-top:10px;
  margin-bottom:10px;
  min-width: 80%;
  margin-left: 10%;
  border-color: var(--accent-color);
}

.score-list-user {
  z-index:1;
  margin:0;
  padding:0;
  position:relative;
  height:40px;
  padding-top:15px;
  padding-left: 16px;
}

.score-list-user-small {
  z-index:1;
  position:relative;
  /* height:20px; */
  padding:0px;  
  margin-bottom: 3px;
}
.score-list-user-small  .score-small{
  font-size: 11px;
}
.avatar-list-user{
   margin-top: -40px;
   position:absolute;
   width: 80px !important;
   height: 80px !important;
   border-radius: 40px !important;
   object-fit:cover;
   border:solid 3px white;
}

.avatar-medium{
  /* margin-top: -40px; */
  /* position:absolute; */
  width: 120px !important;
  height: 120px !important;
  border-radius: 40px !important;
  object-fit:cover;
  border:solid 3px white;
}


.avatar-small{
  width: 40px !important;
  height: 40px !important;
  border-radius: 22px !important;
  object-fit:cover;
  border:solid 2px white;
}
.avatar-smaller{
  width: 30px !important;
  height: 30px !important;
  border-radius: 18px !important;
  object-fit:cover;
  border:solid 1px white;
}

.avatar-bmg-small{
  width: 40px !important;
  height: 40px !important;
}

.avatar-bmg-smaller{
  width: 30px !important;
  height: 30px !important;
}

.media-list-user {
  height:80px;
  object-fit: cover;
}

.tab-content{
  font-family: "Poppins", sans-serif !important;
  display: flex;
  background-color: white;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  padding-bottom: 40px;
  height:77vh;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
}

.tab-block{
  width: calc(100% - var(--tab-width));
}
.tab-block.folded{
  width: 100%;
}
.tab-block.user-block.folded{
  padding-top: 40px;
  width: 100% !important;
}
.ant-tabs-nav{
  margin:0 !important;

}
.ant-tabs-tab{
  padding:10px 0 !important;
  background: none;
  width:168px;
}
.ant-tabs-tab-btn,
.ant-tabs-tab-active {
  color:black !important;
  font-family: "Poppins", sans-serif !important;
  font-size:13px !important;
  font-weight: 618;  
}
.ant-tabs-tab-btn{
  width: var(--tab-width);
}
.user-general{
  display:flex;
  flex-direction: column;
  width: var(--tab-width);
  padding-top: 16px;
}
.user-general.folded{
  width: 10px;
  padding-top: 6px;
}
.user-general.unfolded{
  padding-top: 6px;
}

.fold-user-general{
  position: absolute;
  top: 0;
  left: 0;
  /* border: solid 2px red; */
  z-index: 10;
  width: 40px;
  height: 40px;
  font-size: 20px;
}
.user-general.folded .fold-user-general{
  top: 10px !important;
}
.avatar-detail-user{
  width: 160px !important;
  height: 160px !important;
  border-radius: 80px !important;
  object-fit:cover;
  border:solid 3px white;
}

.divider-detail-user {
  padding:0;
  width:80%;
  height: 1px;
  margin-top:10px;
  margin-bottom:10px;
  min-width: 80%;
  margin-left: 10%;
  border-color: var(--accent-color);
}
.divider-detail-user-presentation {
  padding:0;
  width:100%;
  height: 1px;
  margin-top:10px;
  margin-bottom:10px;
  min-width: 100%;
  border-color: var(--accent-color);
}

.score-detail-user {
  z-index:1;
  margin:0;
  padding:0;
  position:relative;
  height:30px;
  display: flex;
  justify-content: center;
}

.media-detail-user {
  height:200px;
  object-fit: cover;
  cursor:pointer;
  border:solid 8px white;
}

.media-detail-user.media-selected{
  border:0 !important;
}
.media-detail-uploader{
  /* border: solid 1px red;
  margin-top:-10px; */
}
.media-detail-add-user {
  margin-left:16px;
  color:white;
  border:0;
  border-radius:6px;
  background-color: black;
  padding:16px;
  font-size:27px;
  margin-top: -32px;
}
.media-detail-add-user:hover{
  opacity:0.6;
}

.header-user-detail{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.score-small{
  font-size: 14px;
}
.score-large{
  font-size: 18px;
}
.bmg-popover{
  cursor: pointer;
}

.link {
  cursor: pointer;
}
.link:hover{
  opacity: 0.6;
}
.media-carousel-container{
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  margin-left: -50px;
}
.media-carousel-container.alt-nb-media{
  margin-left: 0;
}
.media-carousel{
  height: 200px;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.media-block{
  position: relative;
  display: inline-flex;
  
}
.arrow-button {
  width: 50px;
  height: 50px;
  font-size: 20px;
  cursor: pointer;
  z-index: 1; 
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;  
  color:#e3e3e3;
}
.arrow-button:hover {
  color:var(--secondary-color);
}

.media-block div{
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity and color as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  cursor: pointer;
  z-index: 1; 
  opacity: 1;
}
.media-block div:hover{
  opacity: 1;
}
.media-block div.add{
  opacity: 0;
}
.media-block div.add:hover,
.media-block div.delete:hover{
  opacity: 1;
}
.media-block div.delete{
  top: initial;
  bottom: 0;
  opacity: 0;
  background-color: red;
}

.detail-block{
  width: 100%;
  margin-top:16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}  
.detail-block .lefter{
  width: 46%;
  display: flex;
  align-items: flex-start;
}
.detail-block .righter{
  width: 46%;
  display: flex;
  align-items: flex-start;
  margin-right:4%;
}

.column-direction{
  display: flex;
  flex-direction: column;
}
p.para {
  text-align:left;
  font-size:0.8rem;
}

.card-content-detail {
  text-align:left;
  font-weight: bold;
  font-size: 13px;
  color:black;
  display: flex;
  justify-content: space-between;  
  width:100% !important;
  /* height:111px; */
}

.font-small{
  font-size: 11px;
  /* border:solid; */
}
.font-medium{
  font-size: 13px !important;
  /* border:solid; */
}
.italic {
  font-style: italic;
}
.large{
  width:250px !important;
}

.language-detail{
  width: 100%;
  padding:6px 0;
}
.language-detail span {
  display:inline-flex;
  /* //padding:4px; */
  justify-content: center;
}

.language-detail span:not(:first-child){
  margin-left:6px;
}
.align-right{
  text-align: right;
  width: 100%;
}
.area-expertise-detail{
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
}
.area-expertise-detail div{
  margin:6px;
}
input {
  background-color: #c9b39b0d;
}
.bmg-textarea{
  border:solid 1px var(--accent-color);
  font-family: "Poppins", sans-serif !important;
  font-size:13px !important;
  background-color: #c9b39b0d;
  border: solid 1px #f0f0f0 !important;    
}
.note-header{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin:10px 0;
}

.ant-input-outlined,
.ant-input-outlined:focus{
  border: solid 1px var(--accent-color) !important;
  box-shadow: none !important;
}

.message-drawer{
  height: 100vh;
  /* text-transform: capitalize; */
  color:var(--secondary-color);
  background-color: white !important;
}
.message-drawer .ant-drawer-header{
  background-color: var(--accent-color) !important;
}

.message-drawer-title{
  display: flex;
  color: var(--secondary-color) !important;
  justify-content: flex-start;
  align-items: center;
}
.message-form{
  width: 450px;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  align-items: center;
}
.message-form.large{
  width: 550px !important;
}
.tab-block .message-form{
  position: relative;
  width: 98%;
  height: calc(75vh - 47vh);  
  margin-top:16px;
}
.tab-block .message-form textarea {
  background-color: #c9b39b0d;
  border: solid 1px #f0f0f0 !important;  
}

.message-thread{
  font-family: "Poppins", sans-serif !important;
  width: 450px;
  height: calc(100% - var(--drawer-height));
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  /* flex-direction: column; */
  flex-direction: column-reverse;
  /* justify-content: flex-end; */
}
.tab-block .message-thread{
  /* width: 55vw; */
  width: 98%;
  /* height: ; */
  height: calc(75vh - 30vh);
  margin-top: 2vh;
  margin-top: 2vh;
  background-color: #c9b39b0d;
  border: solid 1px #f0f0f0;
}
.tab-block .message-thread .no-data{
  padding-bottom: 24vh;
  color: var(--accent-color);
}

.message-row{
  width: 95%;
  display: flex;
  padding:2px;
  align-items: flex-start;
  margin:16px;
  align-self: flex-end; 
}
.message-left div{
  display: flex;
  flex-direction: column;  
  align-items: flex-start;
}
.message-right div{
  display: flex;
  flex-direction: column;  
  align-items: flex-end;
}
.message-left{
  justify-content: flex-start;
}
.message-right {
  justify-content: flex-end;
}
.header-actions{
  display: flex;
  align-items: center;
}
.bmg-card .ant-badge{
  position: absolute;
  margin-top: -10px;
  left: 6px;
  box-shadow: none;  
}
.ant-badge .ant-badge-count{
  position: absolute;
  box-shadow: none;
}
.ant-layout-sider .ant-badge .ant-badge-count{
  margin-left:-40px;
  position: relative;
  padding-top:3px;
  box-shadow: none;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.label-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;  
}
.label-list .label-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;  
}

.quote-detail{
  min-width: 300px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
}

.tab-form{
  margin:33px  auto;
  width: 80%;
}
.tab-form.steps{
  width: 90%;
}
.tab-form-block{
  display: flex;
  margin:6px auto;
  justify-content: space-between;
}
.tab-form-block-submit{
  display: flex;
  margin:16px auto;
  justify-content: center;
}

.tab-form-block-column{
  width: 48%;
}
 
.page-card  .tab-form-block{
  width: 80%;
}

.list-step input,
.list-step select,
.tab-form input,
.tab-form select {
  background-color: #c9b39b0d !important;
}
.list-step input:hover,
.list-step select:hover,
.tab-form input:hover,
.tab-form input:focus{
  background-color: white !important;
}

.column-left {
  display:flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-label-name {
  width: 100%;
  color: var(--accent-color);
  text-align: left;
}
.form-label-name.black {
  color: black;
}
.form-label-name span.label{
  font-size: 10px;
  color:black;
}
.ant-steps {
  font-family: "Poppins", sans-serif;  
}
.ant-steps-item-title{
  font-size:12px !important;
}
.ant-steps .ant-steps-item-process .ant-steps-item-icon {
 background-color: var(--accent-color);
 border-color: white;
}


.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: transparent;
  border-color: transparent;
}
.ant-steps-icon{
  color:white !important;
}
.ant-steps .ant-steps-item-finish .ant-steps-icon {  
  color: var(--accent-color) !important;
}
.ant-steps-item-title::after {
  background-color: var(--accent-color) !important;
}

.ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: transparent !important;
}
.ant-steps-item-container[role='button']:hover .ant-steps-item-title{
  color: black !important;
}

.step-content{
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
  margin-top: 32px;
  display: flex;
  background-color: white;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
}
.step-title{
  width: 100%;
  margin: 16px 0 0 10%;
  text-align: left;
}
.step-container{
  margin:11px  auto;
  width: 90%;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.step-container.step2{
  width: 100%;
}
.step-navigation{
  font-size: 22px;
}
.divider-full {
  border-color: var(--accent-color);
}

.page-card {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background-color: white;
    padding: 32px;
    border-radius: 16px;
}
.button-step-disabled,
.button-step{
  display:flex;
  background-color: var(--accent-color);
  color:black;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-size:14px;
  cursor:pointer;
  border:white;
  text-align: center;
  padding: 0;
  padding: 6px 0;
}
.button-step-disabled{
  cursor:default;
  background-color:#e2dcd5b0;
}
.button-step:hover{
  background-color:#cab39bb0;
}

.operation-form-block{
  width: 30%;
  padding:16px;
  border:solid 1px var(--accent-color);
  margin:6px;
  border-radius: 6px;
  background-color: var(--accent-light-color);
}
.list-form-line-edit{
  margin:6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.list-form-line-edit span{
  color:var(--accent-color);
}
.ant-picker-outlined{
  border-color:var(--accent-color) !important;
}

.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color:var(--accent-color) !important;
}
.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-selected .ant-picker-cell-inner{
  background: var(--accent-color) !important;
}

.ant-picker-cell-range-start:before,
.ant-picker-cell-range-end:before,
.ant-picker-cell-in-range:before{
  background: var(--accent-color) !important;
}

.ant-picker-cell-in-range .ant-picker-cell-inner{
  color:white;
}

.workforce-location {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.workforce-row {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin:6px 0;
}
.workforce-tabbed-small{
  margin-left: 6px;
}
.workforce-tabbed-large{
  margin-left: 16px;
}
.grey-bg{
  background-color: #f6f6f6;
  border-radius: 6px;
}
.row-spread {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.number-select {
  width: 66px;
}

.step-mission-list{
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;
  max-height: 400px;
  min-height: 80px;
  overflow-y: auto;
  font-size:13px;
}

.step-mission-list.full-scale{
  max-height: 66vh;
  width: 100%;
  overflow-y: auto;
}

.step-mission-item{
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 6px;
  border:solid 1px var(--accent-color);
  padding:16px;
  margin:6px auto;
  background-color: var(--accent-light-color);
}

.cartouche-mission-item_blank,
.cartouche-mission-item{
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 6px;
  border:solid 1px var(--accent-color);
  padding:16px;
  margin:6px 0;
  background-color: var(--accent-light-color);
}
.cartouche-mission-item_blank{
  border:solid 0;
  background-color: white;
  margin:6px 0;
  border-radius: 0;
  padding:0 16px;
  margin:0;
  justify-content: center;
}

.step-list{
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.step-list span{
  font-size:13px;
}

.drawer-mission-item{
  width: 90%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 6px;
  border:solid 1px #efefef;
  padding:16px;
  margin:6px auto;
  /* background-color: var(--accent-light-color); */
}
.drawer-mission-item.mission-selected{
  background-color: var(--accent-light-alt-color);
  border:solid 1px var(--accent-color);
}
.quote-sheet{
  font-family: "Poppins", sans-serif !important;
  /* font-size: 12px !important; */
  margin:16px auto;
  display: flex;
  font-size: 13px;
  flex-direction: column;
  width: 100%;
  border:solid 1px var(--accent-color);
  border-radius: 6px;
  padding: 16px;
  background-color: var(--accent-light-color);

}
.quote-sheet span{
  font-size: 13px;
}
.quote-sheet-row{
  display: flex;
  align-items: center;
  width: 100%;
  margin: 6px auto;
  justify-content: space-between;
}
.quote-sheet-row-label{
  display: flex;
  align-items: center;
  width: 100%;
  margin: 6px auto;
  justify-content: flex-start;
}

.tab-block .ant-table-body{
  border-radius:0 10px;
  /* font-size: 12px; */
}

.tab-table-row-block{
  display:flex;
  justify-content: flex-start;
  align-items: center;
}

.tab-table-row-span{
  margin-right:6px;
  font-size: 12px;
  font-weight: 500;
}

/* .tab-block .custom-table td:first-child:not(:last-child){
  width: 32px !important;
} */

/* .ant-table-selection-column {
  width: 33px !important;
} */

.ant-modal-title{
  font-family: "Poppins", sans-serif !important;
  font-size: 16px !important;
  color:var(--accent-color) !important;
  font-weight: 800 !important;
}

.mission-table-staff-block {
  display:flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 10px;
  flex-direction: row;
}

.mission-table-role-row {
  display:flex;
  width:100%;
  flex-direction:column;
}
.is-header {
  background-color: white;
  height: 130px;
}

.mission-table-role-cell {
  width:16%;
  min-width:180px;
  max-width:220px; 
}
.mission-table-staff-row {
  display:flex;
  width:100%;
  flex-direction:row;
}
.mission-table-staff-cell {
  display:flex;
  text-align:center;
  width:100%;
  flex-direction:column;  
}
.mission-table-staff-row-reverse{
  width:16%;
  min-width:180px;
  max-width:220px; 
}

.mission-table-staff-cell-reverse {
  display:flex;
  text-align:center;
  width:100%;
  flex-direction:row;
}
.mission-rollover{
  background-color:var(--accent-light-alt-color);
  border-radius: 6px;
}
.custom-checkbox.bigger .ant-checkbox-inner {
  width: 32px;  
  height: 32px; 
}
.custom-checkbox.bigger .ant-checkbox-inner::after {
  width: 14px;
  height: 14px; 
  margin-left: 1px;
}
.custom-checkbox.big .ant-checkbox-inner {
  width: 24px;  
  height: 24px; 
}

.custom-checkbox.big .ant-checkbox-inner::after {
  width: 10px;
  height: 10px; 
  margin-left: 1px;
}

.custom-checkbox.big .ant-checkbox {
  transform: scale(1); /* Adjust scale */
}
.user-presentation-block-checkbox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}


._3_ygE ._WuQ0f,
._2eZzQ,
._34SS0 ._3lLk3{
  display:none !important;
}
._3_ygE ._WuQ0f:first-of-type ,
._34SS0 ._3lLk3:first-of-type {
  display: block !important;
  margin-top: 16px;
  text-transform: uppercase;
}

.col-large ._3_ygE ._WuQ0f:first-of-type ,
.col-large  ._34SS0 ._3lLk3:first-of-type  {
  min-width: 250px !important;
}

._2dZTy:nth-child(even) {
  fill: var(--accent-light-color) !important;
}
._34SS0:nth-of-type(even) {
  background-color: var(--accent-light-color) !important;
}

.gantt-tool-tips-block {
  min-width: 200px;
  display: flex;
  flex-direction: column;
  background-color:white;
  padding:6px;
  align-items: flex-start;
  border-radius: 6px;
  color:white;
}
._3zRJQ {
      font-weight: normal !important; /* Change font color of task labels */
}
._3zRJQ::first-letter{
  text-transform: capitalize;
}
.view-mode-switcher {
  display: flex;
  width: 96%;
  gap: 6px;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
}

.view-mode-switcher .view-option {
  padding: 3px 10px;
  cursor: pointer;
  border: 1px solid var(--accent-color);
  border-radius: 5px;
  background-color: white;
}

.view-mode-switcher .view-option.active {
  background-color: var(--accent-color);
  border: 1px solid var(--accent-color);
  color: white;
}

.fullpage-gantt{
  width: 96%;
  margin-left: 2%;
  height: 100%;
}

.staff-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6px; /* Adjust the gap between the items as needed */
  margin: 6px;
}

.ant-picker-body thead th::first-letter{
  text-transform: uppercase;
}

.op-status-1 {
  background-color:var(--red-color) !important;
}
.op-status-2 {
  background-color:var(--green-color) !important;
}
.op-status-3 {
  background-color:var(--orange-color) !important;
}

.agenda-events{
  width:100%;
  /* border:solid; */
  padding: 0;
    /* margin: 0; */
}
.agenda-events li{
  width:100%;
  /* border:1px red solid; */
}
.ant-picker-calendar-header{
  margin-right: 16px;
}
.ant-picker-calendar-date-today {
  border:solid !important;
  border-color:var(--accent-color) !important ;
  border-width: 2px !important;
}

.ant-picker-calendar .ant-picker-cell-selected .ant-picker-cell-inner{
  background-color:var(--accent-highlight-color) !important ;
}

.ant-picker-cell-selected .ant-picker-calendar-date-value{
  color:white !important;
  font-weight: bold;
  /* background-color: var(--accent-light-color); */
}
.ant-picker-calendar-date{
  border-radius: 6px !important;
  padding:6px !important;
  /* height: 168px !important; */
}
.ant-picker-calendar-date-content{
  height: 130px !important;
}
.ant-picker-calendar-full .ant-picker-calendar-mode-switch{
  display:none;
}
.ant-select-selection-item{
  text-transform: capitalize;
}

.presentation-block{
  display:flex;
  margin-top: 33px;
  margin-left: 2%;
  width: 96%;
  flex-direction: row;
  justify-content: space-between;
}
.presentation-block-inner{
  margin-left:16px;
  width:55%;
}

.has-contract{
  color: var(--green-color);
}

@media (min-width: 601px) and (max-width: 1023px) {
  /* CSS rules for devices with a width between 601px and 1023px ( tablet devices) */
}


@media (max-width: 1024px) {

  .ant-layout-sider{
    display: none;
  }

  .mobile-menu{
    display: contents;
  }
.mobile-menu-selector{
  font-size: 1.3rem;
  color: var(--accent-color);
}
.mobile-menu-items{
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  background-color: white;
  /* padding: 16px; */
  text-align: left;
}
  .dashboard-layout-row{
    flex-direction: column;
  }
  .dashboard-layout-column{
    /* border:solid; */
  }
  .dashboard-card.large {
    width: 100% !important;
}
.flex-column{
  flex-direction: column;
  align-items: flex-start;
}
.dashboard-layout-column{
  margin:16px 0;
  width: 90%;
}

  .ant-table {
    font-size: 12px; /* Adjust the font size for mobile */
  }

  .ant-table-thead > tr > th, 
  .ant-table-tbody > tr > td {
    padding: 8px; /* Adjust padding for table cells */
  }


  .tab-form-block {
    flex-direction: column;
  }
  .tab-form-block-column {
    width: 100%;
    margin: 10px 0 !important;
  }
  .ant-row{
    flex-direction: column; 
  }
  .header-sorter{
    display: none;
  }
  .header-search{
    width: 50px  !important;
  }
  .header-search input {
    width: 50px  !important;
    margin: 0 !important;
  }
  .ant-col{
    margin-left: 10%;
  }
  .message-row {  
    margin: 16px 0;
  }
  .ant-table-cell{
    /* max-height: 30px !important; */
  }
  .filters{
    margin-left: 0 !important;
    width: 40px !important;
    margin-left: 10px !important;
  }
  h2{
    font-size: 16px;
  }
  .-gant-col1 {
    width: 50px !important;
    min-width: 50px !important;
    max-width: 150px !important;
  }
  .-gantt-switcher-button {
    padding: 4px !important;
  }
  .detail-block{
    flex-direction: column;
    padding-left: 16px;
  }
  .detail-block .lefter,
  .detail-block .righter {
    width: 90%;
  }
  .tab-content {
    min-height: 70vh;
    height: 100% !important;
  }
  .presentation-block{
    flex-direction: column;
  }
  .presentation-block-inner{
    /* margin-left:16px; */
    width:90%;
  }
  .presentation-block-inner-first{
    width:90%; 
  }
  .tab-block{
    width:100% !important;
  }
  .user-general {
    width:100% !important;
    /* width: 800px !important; */
  }
  .user-general.folded {
    width: 10px !important;
  }
  .ant-drawer-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
  }
}